<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          GammaTeam Security specializes in providing <router-link to="/services">security guard services</router-link> for new and ongoing construction projects. We know safety is a serious issue that is common among many industries, but perhaps even more so at manufacturing and industrial sites. Theft and vandalism are major problems during the construction phase of all projects.<br /><br />

          GammaTeam Security and our office staff are extra "eyes and ears" at your work site. Our team can add value to manufacturing and industrial sites' <router-link to="/services">safety programs</router-link> in many ways! This is what GammaTeam Security can do you for you.
        </p>
        <div class="flex-container">
          <div class="services_subsection" v-for="(item, i) in items" :key="i">
            <h3>{{ item.label }}</h3>
            {{ item.text }}
          </div>
        </div>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ConstructionSiteSecurity',
  data: () => ({
    items: [
      {
        label: 'On Demand Service',
        text: 'Upon your request, our armed or unarmed security guards will be promptly dispatched to secure and protect your construction site and equipment, and patrol the construction area'
      },
      {
        label: 'Regulatory Compliance Consulting',
        text: 'Manufacturing and industrial sites have many regulations that vary by sector. Our security team can assist in maintaining regulatory compliance'
      },
      {
        label: 'Safety Hazard Mitigation',
        text: 'Our security guards are willing to identify safety hazards that might otherwise go unnoticed and report them before they cause accidents or require expensive clean-up measures'
      },
      {
        label: 'Communication & Prevention',
        text: 'Patrolling Security Officers use mobile technology to prevent incidents and accidents, theft and vandalism as soon as they are spotted, allowing for immediate response and lower risks'
      }
    ]
  })
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/construction.jpg");
}

/* media queries */
@media screen and (max-width: 1160px) {
  section.main article div.services_subsection {
    width: 49%;
    clear: both;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 920px) {
  section.main article div.services_subsection h3 {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 780px) {
  section.main article div.services_subsection h3 {
    font-size: 1.1em;
  }
}
</style>
